<template>
  <div id="dashboard">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="envelope" /> Solicitudes</h1>
        </div>
        <div class="card-body">
          <div class="container col-lg-8 col-md-12">
            <div class="card">
              <div class="card-header">
                <h2>
                  <b-icon icon="journal-arrow-up" /> Formulario de pago de
                  matricula
                </h2>
              </div>
              <div class="card-body">
                <b-form>
                  <b-alert
                    :show="dismissCountDown"
                    variant="danger"
                    @dismiss-count-down="countDownChanged"
                  >
                    <strong>Error. {{ error }},</strong> ocultando en
                    {{ dismissCountDown }} segundos...
                  </b-alert>
                  <!-- Eliminado temporalmente para pruebas de matricula
                    <div class="form-group">
                    <label for="exampleInputEmail1"><b>Solicitud:</b></label>
                    <b-form-select
                      class="form-control"
                      v-model="datos.idRegister"
                      :options="foods"
                      required
                    ></b-form-select>
                  </div>
                  <div class="form-group my-3"> -->
                  <div class="form-group">
                    <label for="exampleInputPassword1"><b>Programa:</b></label>
                    <b-form-select
                      class="form-control"
                      v-model="datos.idProgram"
                      :options="programas"
                      :disabled="id != null"
                      required
                    ></b-form-select>
                  </div>
                  <div class="form-group my-3">
                    <label for="exampleInputPassword1"><b>Monto:</b></label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="datos.amount"
                      placeholder="Ingrese monto"
                      required
                    />
                  </div>
                  <div class="form-group my-3">
                    <label for="exampleInputPassword1"><b>Banco:</b></label>
                    <b-form-select
                      class="form-control"
                      v-model="datos.bank"
                      :options="bancos"
                      required
                    ></b-form-select>
                  </div>
                  <div class="form-group my-3">
                    <label for="exampleInputPassword1"
                      ><b>Número de operación:</b></label
                    >
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Ingrese número"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1"
                      ><b>Imagen de voucher:</b></label
                    >
                    <b-form-file
                      type="file"
                      browse-text="Buscar"
                      placeholder="Seleccionar una imagen aqui"
                      @change="previewImage"
                      accept="image/*"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1"
                      ><b>Comentario:</b></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="datos.comment"
                      placeholder="Ingrese comentario"
                      required
                    />
                  </div>
                  <button
                    type="button"
                    style="
                      background-color: #1d425e;
                      border: none;
                      color: white;
                    "
                    class="btn form-control mt-2"
                    @click="saveItem()"
                  >
                    Enviar solicitud
                  </button>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uuid } from "vue-uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: "",
      imageData: null,
      uploadValue: 0,
      error: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      datos: {
        idRegister: null,
        idProgram: null,
        amount: "",
        bank: null,
        document: "",
        comment: "",
      },
      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
      },
      foods: [{ text: "-- Seleccione solicitud --", value: null }],
      bancos: [
        { text: "-- Seleccione banco --", value: null },
        "BCP",
        "Banco de la Nación",
      ],
      programas: [
        { text: "-- Seleccione Programa --", value: null },
        { text: "Seguridad Industrial y Minera", value: 1643213557850402 },
        { text: "Computación e Informática", value: 1643213557913560 },
        { text: "Marketing y Diseño Publicitario", value: 1643213557935117 },
        {
          text: "Contabilidad Financiera Computarizada",
          value: 1643213557948208,
        },
      ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },
    //Se obtiene la terminacion segun el nombre
    getFileExtension(filename) {
      return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    },

    onUpload() {
      this.picture = null;
      var nameUrl =
        uuid.v1() + "." + this.getFileExtension(`${this.imageData.name}`);
      const storageRef = firebase
        .storage()
        //uuid genera claves unicas
        .ref(nameUrl)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
        }
      );
      return nameUrl;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(data) {
      this.error = data;
      this.dismissCountDown = this.dismissSecs;
    },
    async initialize() {
      let res = await this.$axios
        .get("/requisition", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
      var aux = [];
      for (var i in res.data) {
        var obj = {
          text: res.data[i].type,
          value: res.data[i].id,
        };
        aux.push(obj);
      }
      this.foods = this.foods.concat(aux);
      this.datos.idProgram = this.id;
      //Agregado temporalmente para pruebas de matricula
      this.datos.idRegister = 1;
    },
    async saveItem() {
      this.selected = "circular";
      if (this.imageData != null) {
        var dato = this.onUpload();
        if (dato != null) {
          this.datos.document = dato;
        }
      }
      if (this.datos.idRegister != null) {
        await this.$axios
          .post("/requisition", this.datos, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          })
          .then(() => {
            this.selected = "";
            this.$message.success("Envio exitoso!");
            this.initialize();
          })
          .catch((e) => {
            this.selected = "";
            this.showAlert(e.response.data.content);
          });
      }
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>